import { Methods } from '../../../../../../Utils/Utils'
import { CONTACTDETAILSICON, EMAILICON, PHONEICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IUser } from '../../IUser'
import './_ContactDetails.scss'

interface IContactDetailsProps {
    item: IUser
}

const ContactDetails = (props: IContactDetailsProps) => {
    const { item } = props
    return (
        <>
            {(Methods.isPopulated(item.ContactName) ||
                Methods.isPopulated(item.ContactNumber) ||
                Methods.isPopulated(item.ContactEmail)) && (
                <div className="group-container">
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={CONTACTDETAILSICON} />
                        </div>
                        <div>{Methods.formatText(item.ContactName, 'Contact Name')}</div>
                    </div>
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={PHONEICON} />
                        </div>
                        <div>{Methods.formatText(item.ContactNumber, 'Contact Number')}</div>
                    </div>
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={EMAILICON} />
                        </div>
                        {Methods.formatText(item.ContactEmail, 'Contact Email')}
                    </div>
                </div>
            )}
        </>
    )
}
export default ContactDetails
