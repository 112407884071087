import { Methods } from '../../../../../../Utils/Utils'
import { STUDENTSICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IUser } from '../../IUser'
import './_StudentDetails.scss'

interface IStudentDetailsProps {
    item: IUser
}

const StudentDetails = (props: IStudentDetailsProps) => {
    const { item } = props
    return (
        <>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={STUDENTSICON} />
                </div>
                {Methods.formatText(item.StudentsDSCR, 'Students')}
            </div>
        </>
    )
}
export default StudentDetails
