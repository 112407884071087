import { ReactNode } from 'react'
import './_CommonTabContainer.scss'
import { Box, Container, useTheme } from '@mui/material'
interface commonTabContainerProps {
    children: ReactNode
    displayTab: boolean
}

const CommonTabContainer = (props: commonTabContainerProps) => {
    const { children, displayTab } = props
    const theme = useTheme()
    return (
        <>
            <Box sx={theme.customSx.globalFormTab(displayTab)}>{children}</Box>
        </>
    )
}
export default CommonTabContainer
