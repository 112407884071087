import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import CustomAvatar from '../CustomAvatar/CustomAvatar'
import { useTranslation } from 'react-i18next'

interface Item {
    Name: string
    ProfileImg: string
    Surname: string
}

interface CommonImageGridProps {
    items: any[]
    componentParams?: any
    hasMore: boolean
    loadMore: () => void
    CRUDEForm?: React.ComponentType<any>
    hideAddition?: boolean
    handleAdd: (item: any) => void
    handleEdit: (item: any) => void
    handleDelete: (id: string, itemsArray: any[]) => void
    handleError: (error: string) => void
    page: number
}

const CommonImageGrid: React.FC<CommonImageGridProps> = ({
    items,
    componentParams,
    hasMore,
    loadMore,
    CRUDEForm,
    hideAddition,
    handleAdd,
    handleEdit,
    handleDelete,
    handleError,
    page,
}) => {
    const sxBox =
        componentParams.showToggle || componentParams.showPieChart
            ? {
                  position: 'relative',
                  padding: '20px',
                  width: '100%',
                  marginTop: '55px',
              }
            : {}

    const [isGettingData, setIsGettingData] = useState<boolean>(true)
    const observerRef = useRef<HTMLLIElement>(null)
    const { t } = useTranslation()

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        if (entry.isIntersecting && hasMore && !isGettingData) {
            setIsGettingData(true)
            loadMore()
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        })

        if (observerRef.current) {
            observer.observe(observerRef.current)
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current)
            }
        }
    }, [hasMore, isGettingData, loadMore])

    useEffect(() => {
        setIsGettingData(false)
    }, [items])

    return (
        <Box sx={sxBox}>
            <Grid container spacing={2}>
                {items.map((item, index) => (
                    <Grid item xs={6} sm={3} md={3} lg={2} key={index} style={{ marginTop: index < 4 ? '0' : '0' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                maxWidth: '100%',
                                backgroundColor: 'var(--white)',
                                p: 2,
                                height: '152px',
                            }}
                        >
                            <CustomAvatar
                                item={item}
                                keyName={Object.keys(item)[0]}
                                itemType={componentParams.avatarType}
                                sizeWidth={70}
                                sizeHeight={70}
                                justViewMode={true}
                            />
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    marginTop: '8px',
                                    fontSize: '14px',
                                    maxWidth: '100%',
                                    wordWrap: 'break-word',
                                    lineHeight: 1.33,
                                    fontWeight: 'bold',
                                    color: 'var(--primary-main)',
                                }}
                            >
                                {item.Name +
                                    ' ' +
                                    (typeof item.Surname1 === 'undefined' ? '' : item.Surname1) +
                                    ' ' +
                                    (typeof item.Surname2 === 'undefined' ? '' : item.Surname2)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            {page === -1 && items.length === 0 && <>{t('No Values')}</>}
            {page === -666 && items.length === 0 && <>{t('Session Expired')}</>}
        </Box>
    )
}

CommonImageGrid.defaultProps = {
    componentParams: { showToggle: false },
}

export default CommonImageGrid
