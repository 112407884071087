export enum Mode {
    DeleteEdit,
    Add,
    Refresh,
    None,
}

export enum Operation {
    Initial,
    Add,
    Delete,
    Edit,
    SaveNotClose,
}
