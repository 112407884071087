import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        backend: {
            // for all available options read the backend's repository readme file
            //loadPath: cnf.LANGUAGEPATH + 'locales/{{lng}}/{{ns}}.json'
            loadPath: process.env.REACT_APP_LANGUAGEPATH + 'locales/{{lng}}.json',
        },
    })

export default i18n
