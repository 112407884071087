import './_UserFormStudentDetails.scss'
import { useTranslation } from 'react-i18next'

import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { IUser } from '../../../IUser'

import UFAutocomplete from '../../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useAuth } from '../../../../../../../contexts/AuthContext'
import { Container } from '@mui/material'

interface UserFormStudentDetailsProps {
    control: any
    disabled: boolean
    setValue: any
    item: IUser
}

const UserFormStudentDetails = (props: UserFormStudentDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props

    const { user } = useAuth()

    return (
        <Container>
            <UFAutocomplete
                name="Students"
                control={control}
                setValue={setValue}
                label={t('Students')}
                idColName="StudentID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID + '/students'}
                parentApi={'users/' + item.UserID + '/students'}
                disabled={disabled}
                chipImageName="ProfileImg"
                startSelectedItems={[]}
            />
        </Container>
    )
}

export default UserFormStudentDetails
