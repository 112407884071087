import AppNew from './AppNew'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { SocketProvider } from './contexts/SocketContext'
import { AlertProvider } from './contexts/AlertContext'
import { LoadingProvider } from './contexts/LoadingContext'
import CommonLoadingSpinner from './components/Common/CommonLoadingSpinner/CommonLoadingSpinner'
import CommonAlert from './components/Common/CommonAlert/CommonAlert'
import { AuthProvider } from './contexts/AuthContext'
import CommonErrorBoundaryWrapper from './components/Common/CommonErrorBoundary/CommonErrorBoundary'
import { ThemeProvider } from '@mui/material/styles'
import eskolaTheme from './theme/eskolaTheme'
import CssBaseline from '@mui/material/CssBaseline'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CurrentScreenProvider } from './contexts/CurrentScreenContext'
import NotificationHandler from './components/Common/NotificationHandler/NotificationHandler'
import FullScreenActionForm from './components/Common/MenuActions/FullScreenActionForm/FullScreenActionForm'
import CommonActionsDialog from './components/Common/MenuActions/CommonActionsDialog/CommonActionsDialog'

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then(registration => {
                if (Notification.permission === 'granted') {
                    console.log('Notifications are already allowed.')
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === 'granted') {
                            console.log('Notification permission granted.')
                        } else {
                            console.log('Notification permission denied.')
                        }
                    })
                }
            })
            .catch(error => {
                console.log('Service Worker registration failed:', error)
            })
    })
}

const container = document.getElementById('root')
if (!container) throw new Error('Root element not found')
const root = createRoot(container)

{
    /*<React.StrictMode>*/
}
root.render(
    <>
        <AlertProvider>
            <CurrentScreenProvider>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                    <SocketProvider>
                        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
                            <AuthProvider>
                                <NotificationHandler />
                                <ThemeProvider theme={eskolaTheme}>
                                    <CssBaseline>
                                        <CommonErrorBoundaryWrapper>
                                            <LoadingProvider>
                                                <CommonLoadingSpinner />
                                                <CommonAlert />
                                                <FullScreenActionForm />
                                                <CommonActionsDialog />
                                                <AppNew />
                                            </LoadingProvider>
                                        </CommonErrorBoundaryWrapper>
                                    </CssBaseline>
                                </ThemeProvider>
                            </AuthProvider>
                        </BrowserRouter>
                    </SocketProvider>
                </GoogleOAuthProvider>
            </CurrentScreenProvider>
        </AlertProvider>
    </>
)
