import React, { ReactNode } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Slide } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import { SxProps, Theme } from '@mui/material/styles'
// Transition component for dialog animation
const Transition = React.forwardRef(function Transition(props: any, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />
})

type CommonDialogDialogProps = {
    open: boolean
    onClose: () => void
    title: string
    children: ReactNode
    customSX?: SxProps<Theme> | undefined
}

const CommonDialog: React.FC<CommonDialogDialogProps> = ({ open, onClose, title, children, customSX }) => {
    return (
        <Dialog
            id="common-dialog"
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            maxWidth="sm"
            sx={typeof customSX != 'undefined' ? customSX : { zIndex: theme => theme.zIndex.modal + 1 }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'inherit',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '8px',
                    color: 'var(--black)',
                    position: 'relative',
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        left: 8,
                        top: 8,
                        color: 'var(--black)',
                    }}
                >
                    <ChevronLeft />
                </IconButton>
                <span>{title}</span>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    )
}

export default CommonDialog
