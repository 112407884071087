import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts'
import { Box, Typography } from '@mui/material'

// Define the type for the data entries
type PieData = {
    name: string
    value: number
}

interface CommonPieChartProps {
    data: PieData[]
}

// Function to generate a color for each data entry
const generateColor = (index: number) => {
    const hue = (index * 137.508) % 360 // Use golden angle approximation for color variation
    return `hsl(${hue}, 70%, 50%)`
}

const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, name }: any) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2 // Adjust label position outside the pie
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${name}: (${value})`}
        </text>
    )
}

const CommonPieChart: React.FC<CommonPieChartProps> = ({ data }) => {
    return (
        <Box sx={{ width: '100%', height: 400 }}>
            <Typography variant="h6" component="div" gutterBottom>
                Title
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        cx="40%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label={renderCustomLabel}
                        labelLine={true}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={generateColor(index)} />
                        ))}
                    </Pie>
                    <Tooltip />
                    {/*<Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                        iconType="circle"
                        formatter={(value: string, entry: any, index: number) => (
                            <span>{`${value}: (${entry.payload.value})`}</span> // Display name and value in the legend
                        )}
                        />*/}
                </PieChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default CommonPieChart
