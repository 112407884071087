import { SxProps, Theme } from '@mui/material/styles'
export enum EInputType {
    Numeric = 0,
    Email = 1,
    Password = 2,
    Telephone = 3,
    Text = 4,
    Address = 5,
}

export interface IInputProps {
    name: string
    control: any
    label: string
    setValue?: any
    required?: string
    disabled?: boolean
    inputType?: EInputType
    fullWidth?: boolean
    url?: string
    hideNone?: boolean
    idColName?: string
    chipImageName?: string
    nameColName?: string | ''
    startSelectedItems?: number[]
    value?: string
    handleOnChange?: (par: number) => void | null
    dontTranslate?: boolean
    autoComplete?: string
    autoFocus?: boolean
    lines?: number
    handleRefreshIfJustOneElement?: () => void | null
    hidden?: boolean
    placeholder?: string
    customSx?: SxProps<Theme>
    items?: any[]
}
