import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useLoading } from '../../../contexts/LoadingContext'

const CommonLoadingSpinner: React.FC = () => {
    const { isLoading } = useLoading()

    return (
        <Backdrop sx={{ position: 'fixed', color: '#fff', zIndex: 200000 }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default CommonLoadingSpinner
