import { ReactNode } from 'react'
import { Container, Grid } from '@mui/material'
import LanguageSelector from '../../components/Views/Onboarding/LanguageSelector/LanguageSelector'
import Copyright from '../../components/Views/Onboarding/Copyright/Copyright'
// import CustomDrawerLanguagesMenu from '../../components/Common/CustomDrawer/CustomDrawerLanguagesMenu/CustomDrawerLanguagesMenu'

interface onboardingProps {
    children?: ReactNode
}

const Onboarding = (props: onboardingProps) => {
    const { children } = props
    return (
        <>
            <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100vh' }}
                flexDirection="column"
            >
                <Container component="main" maxWidth="xs">
                    <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        className="eskolaLogo"
                        sx={{ mb: 2 }}
                    >
                        <img src="/assets/images/theme/logo-eskola.svg" alt="Eskola brand" />
                    </Grid>
                    {children}
                    <LanguageSelector />
                    {/*<CustomDrawerLanguagesMenu alertOpened={false} />*/}
                    <Copyright />
                </Container>
            </Grid>
        </>
    )
}

export default Onboarding
