import { Button, Box } from '@mui/material'
import { t } from 'i18next'
import GridList from '../../CommonInfiniteScrollList/GridList/GridList'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { IAction } from '../IAction'
import { useAlert } from '../../../../contexts/AlertContext'
import { useTranslation } from 'react-i18next'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'

interface ActionMenusButtonProps {
    action: IAction
    selectedRecordIDs: number[]
    index: number
}

const ActionMenusButton: React.FC<ActionMenusButtonProps> = (props: ActionMenusButtonProps) => {
    const { action, selectedRecordIDs, index } = props
    const { setAlert } = useAlert()
    const { t } = useTranslation()
    const { selectedAction, setOpenSwipeScreenDialog, handleSelectedAction } = useCurrentScreen()
    return (
        <Box
            sx={{
                width: '100%',
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
            }}
        >
            <Button
                onClick={event => {
                    handleSelectedAction(action.shortCode)
                    event.stopPropagation()
                }}
                variant="contained"
                fullWidth
                className="actionButton"
                style={{
                    marginTop: '1rem',
                    marginBottom: '0rem',
                    alignSelf: 'center',
                    backgroundColor: 'var(--primary-main)',
                    maxWidth: '350px',
                }}
            >
                <CustomSVG strSVG={action.icon} customSX={{ mr: 1 }} />
                {t(action.label)}
            </Button>
        </Box>
    )
}
export default ActionMenusButton
