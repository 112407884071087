import { Methods } from '../../../../../../Utils/Utils'
import { ORGANIZATIONSICON, ROLESICON, SUBJECTICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IUser } from '../../IUser'
import './_RoleDetails.scss'

interface IRoleDetailsProps {
    item: IUser
}

const RoleDetails = (props: IRoleDetailsProps) => {
    const { item } = props
    return (
        <>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={ORGANIZATIONSICON} />
                </div>
                {Methods.formatText(item.OrganizationDSCR + '', 'Organization')}
            </div>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={ROLESICON} />
                </div>
                {Methods.formatText(item.RoleDSCR, 'Role')}
            </div>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={SUBJECTICON} />
                </div>
                {Methods.formatText(item.SubjectsDSCR, 'Role')}
            </div>
        </>
    )
}
export default RoleDetails
