import './_CustomSVG.scss'
// CustomSVG.tsx

import { SvgIcon } from '@mui/material'

import { SxProps, Theme } from '@mui/material/styles'

interface CustomSVGProps {
    width?: number
    height?: number
    strSVG: string
    customFontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined
    customSX?: SxProps<Theme> | undefined
    customColor?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'action'
        | 'disabled'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined
}

const CustomSVG = (props: CustomSVGProps) => {
    const { width, height, strSVG, customFontSize, customSX, customColor } = props

    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fontSize={customFontSize}
            sx={customSX}
            color={customColor}
        >
            {/*<path fill="none" d="M0 0h24v24H0z" />*/}
            <g dangerouslySetInnerHTML={{ __html: strSVG }} />
            {/* <path d={strSVG} strokeWidth="0" fill="currentColor" />*/}
        </SvgIcon>
    )
}

export default CustomSVG
