import { Grid, Typography, Box } from '@mui/material'
import CustomAvatar from '../../CustomAvatar/CustomAvatar'

interface GridListProps {
    componentParams: any
    items: any[]
}

const GridList: React.FC<GridListProps> = (props: GridListProps) => {
    const { componentParams, items } = props
    return (
        <Grid container spacing={2}>
            {items.map((item, index) => (
                <Grid item xs={6} sm={3} md={3} lg={2} key={index} style={{ marginTop: index < 4 ? '0' : '0' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            maxWidth: '100%',
                            backgroundColor: 'var(--white)',
                            p: 2,
                            height: '152px',
                        }}
                    >
                        <CustomAvatar
                            item={item}
                            keyName={Object.keys(item)[0]}
                            itemType={componentParams.avatarType}
                            sizeWidth={70}
                            sizeHeight={70}
                            justViewMode={true}
                        />
                        <Typography
                            variant="subtitle1"
                            sx={{
                                marginTop: '8px',
                                fontSize: '14px',
                                maxWidth: '100%',
                                wordWrap: 'break-word',
                                lineHeight: 1.33,
                                fontWeight: 'bold',
                                color: 'var(--primary-main)',
                            }}
                        >
                            {item.Name +
                                ' ' +
                                (typeof item.Surname1 === 'undefined' ? '' : item.Surname1) +
                                ' ' +
                                (typeof item.Surname2 === 'undefined' ? '' : item.Surname2)}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}
export default GridList
