import './_LanguageSelector.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    SelectChangeEvent,
    MenuItem,
    FormControl,
    Select,
    Grid,
    OutlinedInput,
    Tooltip,
    InputAdornment,
    ListItemIcon,
} from '@mui/material/'
import LanguageIcon from '@mui/icons-material/Language'

interface LanguageOption {
    code: string
    name: string
}

const availableLanguages: LanguageOption[] = [
    { code: 'ar', name: 'Arabic' },
    { code: 'eu', name: 'Basque' },
    { code: 'es', name: 'Spanish' },
    { code: 'en', name: 'English' },
    { code: 'en-GB', name: 'British English' },

    // Add more language options here...
]

const LanguageSelector: React.FC = () => {
    const { t, i18n } = useTranslation()

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const languageCode = event.target.value as string
        i18n.changeLanguage(languageCode)
    }

    return (
        <Grid container display="flex" justifyContent="center" alignContent="center">
            <FormControl id="languages" variant="standard">
                <Select
                    labelId="language-selector-label"
                    name="language-selector"
                    id="language-selector"
                    value={i18n.language}
                    onChange={handleChange}
                    label="Language"
                    input={
                        <OutlinedInput
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                paddingRight: '10px',
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Tooltip title={t('Language')}>
                                            <LanguageIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                </InputAdornment>
                            }
                        />
                    }
                >
                    {availableLanguages.map(language => (
                        <MenuItem id={language.code} key={language.code} value={language.code}>
                            {t(language.name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    )
}

export default LanguageSelector
