import React, { useEffect, useRef, useState } from 'react'
import { List, Box, Container, popoverClasses, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Mode, Operation } from '../../Types/CommonType'
import './_CommonInfiniteScrollPaginatedList.scss'
import CommonForm from '../../CommonForm/CommonForm'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import PaginatedAsyncAutocomplete from '../../UseForm/PaginatedAsyncAutocomplete/PaginatedAsyncAutocomplete'
import ListToolBar from '../ListToolBar/ListToolBar'
import CustomListItem from '../CustomListItem/CustomListItem'
import ListAdditionButton from '../ListAdditionButton/ListAdditionButton'
import GridList from '../GridList/GridList'
import { use } from 'chai'
import FullScreenActionForm from '../../MenuActions/FullScreenActionForm/FullScreenActionForm'
import CommonActionsDialog from '../../MenuActions/CommonActionsDialog/CommonActionsDialog'

interface CommonInfiniteScrollPaginatedListProps {
    loadMore: () => void
    items: any[]
    hasMore: boolean
    itemType?: string
    CRUDEForm?: React.ComponentType<any>
    CRUDEFormURL?: string
    filterURL: string
    filter: string
    hideAddition?: boolean
    handleAdd: (item: any) => void
    handleEdit: (item: any) => void
    handleDelete: (id: string, itemsArray: any[]) => void
    handleError: (error: string) => void
    handleFilterChange: (queryWords: string) => void
    page: number
    variant?: 'default' | 'other'
    componentParams?: any
    hideMultiSelect?: boolean
    ListItemTextAttributes: React.ComponentType<any>
}

const CommonInfiniteScrollPaginatedList: React.FC<CommonInfiniteScrollPaginatedListProps> = ({
    loadMore,
    items,
    hasMore,
    CRUDEForm,
    CRUDEFormURL,
    filter,
    hideAddition,
    handleAdd,
    handleEdit,
    handleDelete,
    handleError,
    handleFilterChange,
    page,
    variant = 'default',
    componentParams,
    hideMultiSelect,
    ListItemTextAttributes,
    filterURL,
    itemType,
}) => {
    const { t } = useTranslation()
    const {
        hasActionsRequireBulk,
        setSelectedRecordIDs,
        actions,
        pushMode,
        popMode,
        popMenu,
        pushMenu,
        menusStack,
        currentScreenItem,
        currentMenuID,
    } = useCurrentScreen()
    const [selectedID, setSelectedID] = useState<number>(0)
    const observerRef = useRef<HTMLLIElement>(null)
    const [formOpeningMode, setFormOpeningMode] = useState<Mode>(Mode.None)
    const [reRenderForm, setReRenderForm] = useState<boolean>(false)
    const [isGettingData, setIsGettingData] = useState<boolean>(true)
    const [checkedItems, setCheckedItems] = useState<number[]>([])
    const [viewMode, setViewMode] = useState<boolean>(true)

    const sxList =
        componentParams !== null
            ? componentParams.showToggle || componentParams.showPieChart
                ? // Show Toggle option
                  {
                      position: 'relative',
                      width: '100%',
                      top: componentParams.filter ? '0px' : '0px',
                  }
                : // Show Pie chart option
                  {
                      position: 'relative',
                      width: '100%',
                      top: componentParams.filter ? '0px' : '0px',
                  }
            : {
                  position: 'relative',
                  width: '100%',
                  top: '0px',
              }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        if (entry.isIntersecting && hasMore && !isGettingData) {
            setIsGettingData(true)
            loadMore()
            if (page === 1) {
                setCheckedItems([])
                setSelectedRecordIDs(checkedItems)
                setViewMode(true)
            }
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        })

        if (observerRef.current) {
            observer.observe(observerRef.current)
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current)
            }
        }
    }, [hasMore, isGettingData, loadMore])

    useEffect(() => {
        setIsGettingData(false)
    }, [items])

    useEffect(() => {
        setCheckedItems([])
        setSelectedRecordIDs(checkedItems)
        loadMore
    }, [filter])

    const handleOpenFormEdit = (id: string) => {
        setFormOpeningMode(Mode.DeleteEdit)
        setSelectedID(parseInt(id))
        pushMode({
            mode: 'form',
            item: items.find(item => {
                return item[Object.keys(item)[0]].toString() === id.toString()
            }),
            url: filterURL.split('/').filter(Boolean).pop() + '',
        })
        pushMenu(menusStack[menusStack.length - 1])
    }

    const handleOpenAdd = (event: React.FormEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setFormOpeningMode(Mode.Add)
        pushMode({
            mode: 'form',
            item: null,
            url: filterURL.split('/').filter(Boolean).pop() + '',
        })
        pushMenu(menusStack[menusStack.length - 1])
    }

    const handlImageUpdated = (filename: string, id?: number) => {
        console.log('handlImageUpdated', filename, id)
    }

    function handleSave<T>(item: T, afterOp: Operation, error?: string): void {
        const tmp = item as unknown as T

        if (error) {
            handleError(error)
        } else {
            switch (afterOp) {
                case Operation.Add:
                    setFormOpeningMode(Mode.None)
                    setReRenderForm(!reRenderForm)
                    handleAdd(tmp)
                    popMode()
                    popMenu()
                    break
                case Operation.Edit: {
                    setFormOpeningMode(Mode.None)
                    setReRenderForm(!reRenderForm)
                    handleEdit(tmp)
                    popMode()
                    popMenu()
                    break
                }

                case Operation.Delete: {
                    setFormOpeningMode(Mode.None)
                    const tmpArra = [] as any
                    tmpArra.push(Object.values(tmp as any)[0])
                    handleDelete(Object.keys(tmp as any)[0], tmpArra)
                    popMode()
                    popMenu()
                    break
                }
            }
        }
    }

    const handleNoSave = () => {
        setFormOpeningMode(Mode.None)
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Ctrl (or Cmd) and S keys are pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
                if (currentScreenItem.mode !== 'form') {
                    //so we dont change the edit mode when the form screen is opened

                    event.preventDefault()
                    event.stopPropagation()
                    setFormOpeningMode(Mode.Add)
                }
            }
        }

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown)

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleOpenAdd])

    const handleCheckBox = (event: React.FormEvent<HTMLButtonElement>, id: number) => {
        event.stopPropagation()
        setCheckedItems(prevChecked => {
            const updatedCheckedItems = prevChecked.includes(id)
                ? prevChecked.filter(tmpID => tmpID !== id) // Remove ID if already selected
                : [...prevChecked, id] // Add ID if not selected

            console.log('Updated Checked Items:', updatedCheckedItems) // Correctly logs updated state
            setSelectedRecordIDs(updatedCheckedItems)
            return updatedCheckedItems
        })
    }
    const handleSelectAll = () => {
        if (checkedItems.length === items.length) {
            // If all are selected, uncheck all
            setCheckedItems([])
            setSelectedRecordIDs([])
        } else {
            // Otherwise, select all
            //const allIndexes = items.map((_, index) => index)
            const allIndexes = items.map((item: any) => item[Object.keys(item)[0]])
            setCheckedItems(allIndexes)
            setSelectedRecordIDs(allIndexes)
        }
    }

    return (
        <>
            <Box display="block" justifyContent="center" alignItems="center" className="filterFixedBar">
                {componentParams && componentParams.filter && (
                    <PaginatedAsyncAutocomplete
                        handleFilterChange={handleFilterChange}
                        url={filterURL}
                        name="autocomplete"
                    />
                )}
                {currentScreenItem.mode == 'list' && Boolean(hideMultiSelect) == false && (
                    <ListToolBar
                        handleSelectAll={handleSelectAll}
                        hideMultiSelect={hideMultiSelect}
                        checkedItems={checkedItems}
                    />
                )}
            </Box>

            {/* Main container */}
            <Container
                sx={{
                    marginTop:
                        hideMultiSelect && (!componentParams || !componentParams.filter)
                            ? '0px'
                            : componentParams.filter
                            ? actions.length > 0
                                ? '150px'
                                : '120px'
                            : '50px',
                }}
            >
                {viewMode && (
                    <List sx={sxList}>
                        {items.map((item: any, index) => (
                            <CustomListItem
                                key={index}
                                index={index}
                                item={item}
                                handleCheckBox={handleCheckBox}
                                checkedItems={checkedItems}
                                componentParams={componentParams}
                                handlImageUpdated={handlImageUpdated}
                                handleOpenFormEdit={handleOpenFormEdit}
                                ListItemTextAttributes={ListItemTextAttributes}
                                itemType={typeof itemType === 'undefined' ? '' : itemType}
                            />
                        ))}
                        <li ref={observerRef} style={{ height: '10px' }}></li>
                        {(typeof hideAddition === 'undefined' || hideAddition === false) && (
                            <ListAdditionButton handleOpenAdd={handleOpenAdd} />
                        )}
                    </List>
                )}
                {!viewMode && (
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        <GridList componentParams={componentParams} items={items} />

                        {page === -1 && items.length === 0 && (
                            <Stack
                                className="noValues"
                                direction="column"
                                spacing={2}
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img src="/assets/images/theme/not-data-found.svg" alt={t('NoValues')} width="270px" />
                                <Typography align="center">{t('NoValues')}</Typography>
                            </Stack>
                        )}
                        {page === -666 && items.length === 0 && <div>{t('SessionExpired')}</div>}
                    </Box>
                )}
            </Container>

            {formOpeningMode !== Mode.None && CRUDEForm && (
                <CommonForm
                    mode={formOpeningMode}
                    id={selectedID}
                    onClose={handleSave}
                    onCloseNoSave={handleNoSave}
                    reRender={reRenderForm}
                    Form={CRUDEForm}
                    FormURL={typeof CRUDEFormURL === 'undefined' ? '' : CRUDEFormURL}
                />
            )}

            {page === -1 && items.length === 0 && (
                <Stack
                    className="noValues"
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img src="/assets/images/theme/not-data-found.svg" alt={t('NoValues')} width="270px" />
                    <Typography align="center">{t('NoValues')}</Typography>
                </Stack>
            )}
            {page === -666 && items.length === 0 && <p>{t('Session Expired')}</p>}
        </>
    )
}

CommonInfiniteScrollPaginatedList.defaultProps = {
    componentParams: {
        PageSize: -1,
        showToggle: false,
        filter: null,
        avatarType: 'images/general',
        showPieChart: false,
        pieChartNameAttr: '',
        pieChartValAttr: '',
    },
}

export default CommonInfiniteScrollPaginatedList
