import { Methods } from '../../../../../../Utils/Utils'
import { SECURITYICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IUser } from '../../IUser'
import './_CredentialDetails.scss'

interface ICredentialDetailsProps {
    item: IUser
}

const CredentialDetails = (props: ICredentialDetailsProps) => {
    const { item } = props
    return (
        <>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={SECURITYICON} />
                </div>
                {Methods.formatText(item.Email, 'Email')}
            </div>
        </>
    )
}
export default CredentialDetails
