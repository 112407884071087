import './_UserFormAdd.scss'
import { IUser } from '../../../IUser'
import { useState } from 'react'
import { Mode, Operation } from '../../../../../../Common/Types/CommonType'
import { useForm } from 'react-hook-form'
import React from 'react'
import UserCard from '../../../Card/UserCard'
import { useTheme } from '@mui/material'
import Profile from '../../../../../../../layouts/Profile/Profile'
import CommonTabLoader from 'components/Common/Tabs/CommonTabLoader/CommonTabLoader'

interface UserFormAddProps {
    item: IUser
    mode: Mode
    operation: Operation
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    onImageUpdated: (filename: string) => void
    counters: { [key: string]: string }
    handleOnCloseNoSave: () => void
    editMode: string
    handleEditCard: (prmType: string) => void
}

const UserFormAdd = (props: UserFormAddProps) => {
    const methods = useForm<IUser>({ defaultValues: props.item })
    const { control, setValue, watch } = methods
    const { item, mode, operation, handleOnCloseNoSave, editMode, onImageUpdated, onClose, handleEditCard } = props
    const [tabValue, setTabValue] = useState('1')
    const theme = useTheme()
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (
        <Profile
            item={item}
            onCloseNoSave={handleOnCloseNoSave}
            tabValue={tabValue}
            methods={methods}
            onClickEdit={handleEditCard}
            nameLabelValue={item.Name + ' ' + item.Surname1 + ' ' + item.Surname2}
            subNameLabelValue={typeof item.RoleDSCR !== 'undefined' ? item.RoleDSCR : ''}
            IDColName="UserID"
            avatarType="images/user"
        >
            <CommonTabLoader
                DetailsTabComponent={UserCard}
                item={item}
                control={control}
                setValue={setValue}
                watch={watch}
                operation={operation}
                formMainAPI="users"
                handleTabChangeParent={handleTabChange}
                commonTabHeaderCustomSX={theme.customSx.globalFormTabListForProfile}
            />
        </Profile>
    )
}

export default UserFormAdd
