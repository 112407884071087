import { IconButton } from '@mui/material'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { TOOLICON } from '../../../../styles/_icons'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
const ActionForFormMode = () => {
    const { currentScreenItem, actions, setOpenSwipeScreenDialog, setOpenQuickActionDialog } = useCurrentScreen()

    const handleOpenFormActionMenusDialog = (event: React.FormEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setOpenQuickActionDialog(false)
        setOpenSwipeScreenDialog(true)
    }

    return (
        <>
            {actions.length > 0 && currentScreenItem.mode === 'form' && (
                <>
                    <IconButton
                        onClick={handleOpenFormActionMenusDialog}
                        edge={false}
                        aria-label="actions"
                        size="large"
                    >
                        <MoreVertIcon />
                    </IconButton>
                </>
            )}
        </>
    )
}
export default ActionForFormMode
