// src/components/Dashboard.tsx
import { Grid, Container } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemReducer, initialItemState } from '../../Reducers/CommonReducer'
import { useAlert } from '../../../../contexts/AlertContext'
import { useLoading } from '../../../../contexts/LoadingContext'
import { Methods } from '../../../../Utils/Utils'
import CommonDashboardWidget from '../CommonDashboardWidget/CommonDashboardWidget'
import api from '../../../../Utils/APIService'
import { useAuth } from '../../../../contexts/AuthContext'

const CommonDashboard: React.FC = () => {
    const [state, dispatch] = useReducer(ItemReducer, initialItemState(null))
    const { setLoading, isLoading } = useLoading()
    const { setAlert } = useAlert()
    const { user } = useAuth()
    const { t, i18n } = useTranslation()

    useEffect(() => {
        const loadMenus = async () => {
            try {
                dispatch({ type: 'Reset' })

                const response = await api.get('users/' + user!.OpUserID + '/dashboardselectedmenus')

                if (response.status === 401) {
                    dispatch({ type: 'SessionExpired' })
                } else {
                    let newItems = response.data
                    newItems = Methods.JSONUnminify(newItems)

                    if (state.page === 1 && newItems.length === 0) {
                        dispatch({ type: 'NoData' })
                    } else {
                        dispatch({ type: 'ItemsLoaded', payload: newItems })
                    }
                    dispatch({ type: 'HasMore', hasMore: false })
                }
            } catch (error: any) {
                if (error.name === 'AbortError') {
                    dispatch({ type: 'Error', payload: 'Previous request canceled' })

                    console.log('Previous request canceled')
                } else {
                    dispatch({ type: 'Error', payload: 'Error fetching options' })

                    setAlert({
                        message: error.message,
                        severity: 'error',
                        datetime: Date.now().toString(),
                    })
                }
            } finally {
                setLoading(false)
            }
        }
        loadMenus()
    }, [i18n.language])

    return (
        <Container>
            {state.ItemsLoaded && state.Items.length > 0 && (
                <Grid
                    display="flex"
                    flexDirection={'row'}
                    container
                    gap={2}
                    sx={{ mt: 4, p: 0 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    {state.Items.map((item: any, index) => (
                        <CommonDashboardWidget
                            index={item.MenuID}
                            key={index}
                            name={item.Name}
                            svgIcon={item.Icon}
                            route={item.Route}
                            color={item.color}
                        />
                    ))}
                </Grid>
            )}
        </Container>
    )
}

export default CommonDashboard
