import './_CardDetails.scss'
import { Box, IconButton, Typography, Container } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'

interface IEditLegendProps {
    title: string
    handleEditCard: (prmType: string) => void
    type: string
}

const EditableLegend = (props: IEditLegendProps) => {
    const { handleEditCard, title, type } = props

    const handleClick = () => {
        handleEditCard(type)
    }
    // Editable legend
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={handleClick}>
            <Typography gutterBottom variant="body1" color="primary" fontWeight="bold">
                {title}
            </Typography>
            <IconButton onClick={handleClick} sx={{ marginLeft: 'auto' }}>
                <EditIcon />
            </IconButton>
        </Box>
    )
}

interface cardDetailsProps {
    children: ReactNode
    cardLabel: string
    cardType: string
    handleEditCard: (prmType: string) => void
}

const CardDetails = (props: cardDetailsProps) => {
    const { children, cardLabel, cardType, handleEditCard } = props
    const { t } = useTranslation()

    return (
        <Container sx={{ p: 0 }}>
            <fieldset style={{ border: 0 }}>
                <EditableLegend title={t(cardLabel)} handleEditCard={handleEditCard} type={cardType} />
                {children}
            </fieldset>
        </Container>
    )
}
export default CardDetails
