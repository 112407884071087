import './_UserFormContactDetails.scss'
import { IUser } from '../../../IUser'
import UFTextField from '../../../../../../Common/UseForm/UFTextField/UFTextField'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { useTranslation } from 'react-i18next'
import { EInputType } from '../../../../../../Common/Interfaces/IInputProps'
import UFCheckBox from '../../../../../../Common/UseForm/UFCheckBox/UFCheckBox'
import { Container } from '@mui/material'

interface UserFormContactDetailsProps {
    item: IUser
    control: any
    setValue: any
    disabled: boolean
}

const UserFormContactDetails = (props: UserFormContactDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props

    return (
        <Container>
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="ContactName"
                label={t('ContactName')}
                disabled={disabled}
            />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="ContactNumber"
                label={t('ContactNumber')}
                disabled={disabled}
            />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="ContactEmail"
                label={t('ContactEmail')}
                disabled={disabled}
                inputType={EInputType.Email}
            />

            <UFCheckBox control={control} name="Is2FA" label={t('Is 2FA')} disabled={disabled} />
        </Container>
    )
}

export default UserFormContactDetails
