// src/utils/apiService.ts
import axios from 'axios'
import { authService } from './AuthService'
import { Options } from '../components/Common/Types/CommonHelper'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

const api = axios.create({
    baseURL: process.env.REACT_APP_METHODS_API,
})

// Function to refresh the token
export const refreshToken = async () => {
    const refreshToken = authService.getRefreshToken()

    if (!refreshToken) {
        authService.removeToken()
        throw new Error('Refresh token is missing, need to login again.')
    }

    try {
        const response = await axios.post(process.env.REACT_APP_METHODS_API + 'users/refreshtoken', {
            refreshToken,
        })

        if (!response.data) {
            authService.removeToken()
            throw new Error('Unable to refresh token, need to login again.')
        }
        const { accessToken, expiresIn } = response.data
        authService.saveToken(accessToken, refreshToken, expiresIn, true)

        return accessToken
    } catch (error) {
        authService.removeToken() // Clear tokens if refresh fails
        throw new Error('Unable to refresh token, need to login again.')
    }
}

// Interceptor for API requests to handle token expiration
api.interceptors.request.use(
    async config => {
        const tokenPayload = authService.getToken()

        if (tokenPayload && authService.isTokenExpired()) {
            try {
                console.log('Token expired, refreshing...')
                const newAccessToken = await refreshToken()
                console.log('Token expired, refreshing...new token is:', newAccessToken)
                tokenPayload.token = newAccessToken
            } catch (err) {
                console.error('Token refresh failed:', err)
                window.location.href = '/' // Redirect to login page
                return Promise.reject(err)
            }
        }

        let Options
        if (tokenPayload) {
            //config.headers['Authorization'] =
            Options = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': tokenPayload.token + '',
                    Accept: 'application/json',
                    'Accept-Language': i18n.language + '',
                },
            }
        } else {
            Options = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Accept-Language': i18n.language + '',
                },
            }
        }
        config.headers = Options.headers

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

export default api
