import { ListItemIcon, Checkbox, ListItemAvatar, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import CustomAvatar from '../../CustomAvatar/CustomAvatar'
import ListItem from '@mui/material/ListItem'

interface CustomListItemProps {
    handleOpenFormEdit: (id: string) => void
    item: any
    checkedItems: any[]
    handleCheckBox: (event: any, id: number) => void
    componentParams: any
    handlImageUpdated: (item: any) => void
    index: number
    ListItemTextAttributes: any
    itemType: string
}

const CustomListItem: React.FC<CustomListItemProps> = (props: CustomListItemProps) => {
    const {
        handleOpenFormEdit,
        item,
        checkedItems,
        handleCheckBox,
        componentParams,
        handlImageUpdated,
        index,
        ListItemTextAttributes,
        itemType,
    } = props
    const { hasActionsRequireBulk } = useCurrentScreen()
    const { t } = useTranslation()

    return (
        <ListItem
            key={index}
            onClick={() => handleOpenFormEdit(item[Object.keys(item)[0]])}
            divider
            className="listItemAvatar"
            sx={{
                borderColor:
                    hasActionsRequireBulk && checkedItems.includes(item[Object.keys(item)[0]])
                        ? 'secondary.main'
                        : 'white',
            }}
        >
            {hasActionsRequireBulk && (
                <ListItemIcon
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {/* List checkbox */}
                    <Checkbox
                        edge="start"
                        checked={checkedItems.includes(item[Object.keys(item)[0]])}
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        inputProps={{
                            'aria-labelledby': `checkbox-list-label-${index}`,
                        }}
                        onClick={event => handleCheckBox(event, item[Object.keys(item)[0]])}
                        sx={
                            hasActionsRequireBulk && checkedItems.includes(item[Object.keys(item)[0]])
                                ? {
                                      minWidth: 'auto',
                                      color: 'primary.main',
                                      '&.Mui-checked': {
                                          color: 'primary.main',
                                          marginLeft: 0,
                                          padding: '1.5rem 0.5rem 1rem 0',
                                      },
                                  }
                                : { padding: '1.5rem 0.5rem 1rem 0', marginLeft: 0, alignSelf: 'flex-start' }
                        }
                    />
                </ListItemIcon>
            )}
            {typeof item.ProfileImg !== 'undefined' && (
                <ListItemAvatar>
                    <CustomAvatar
                        item={item}
                        keyName={Object.keys(item)[0]}
                        itemType={
                            itemType === ''
                                ? componentParams.avatarType
                                    ? componentParams.avatarType
                                    : 'images/general'
                                : itemType
                        }
                        onImageUpdated={handlImageUpdated}
                        sizeWidth={50}
                        sizeHeight={50}
                        justViewMode={true}
                    />
                </ListItemAvatar>
            )}
            <ListItemText
                className="listItemText"
                sx={{
                    color:
                        hasActionsRequireBulk && checkedItems.includes(item[Object.keys(item)[0]])
                            ? 'primary.main'
                            : 'primary.main',
                }}
                primary={<ListItemTextAttributes item={item} />}
                // Separate the title of the ListItem from the details of the ListItem
                // primary={<ListItemTextPrimary item={item} />}
                // secondary={<ListItemTextAttributes item={item} />}
            />
        </ListItem>
    )
}
export default CustomListItem
