import { useCallback, useEffect, useReducer } from 'react'
import { IFormProps } from '../Interfaces/IFormProps'
import './_CommonForm.scss'
import { ItemFormReducer, initialItemFormState } from '../Reducers/CommonFormReducer'
import { Mode, Operation } from '../Types/CommonType'
import { useAlert } from '../../../contexts/AlertContext'
import api from '../../../Utils/APIService'

interface CommonFormProps extends IFormProps {
    Form: React.ComponentType<any>
    FormURL: string
}

const CommonForm = (props: CommonFormProps) => {
    const { mode, onClose, onCloseNoSave, id, Form, FormURL } = props
    const [state, dispatch] = useReducer(ItemFormReducer, initialItemFormState())
    const { setAlert } = useAlert()

    const loadItem = useCallback(async () => {
        if (id === undefined) {
            return
        }
        const response = await api.get(FormURL + '/' + id)

        if (!response.data) {
            setAlert({
                message: response.status + ':' + response.statusText,
                severity: 'error',
                datetime: Date.now().toString(),
            })
            dispatch({ type: 'Error', payload: `HTTP error! status: ${response.status}` })
        } else {
            //const data = await response.json()
            const data = response.data
            dispatch({ type: 'ItemLoaded', payload: data[0] })
        }
    }, [id, FormURL])

    useEffect(() => {
        if (mode !== Mode.Add) {
            loadItem()
        } else {
            dispatch({ type: 'ItemLoaded', payload: initialItemFormState() })
        }
    }, [loadItem, mode])

    return (
        <>
            {state.itemLoaded && (
                <Form
                    item={state.item as any}
                    onClose={onClose}
                    onCloseNoSave={onCloseNoSave}
                    mode={mode}
                    operation={mode === Mode.Add ? Operation.Add : Operation.Edit}
                    counters={state.counters}
                />
            )}
        </>
    )
}

export default CommonForm
