import { useEffect } from 'react'

const NotificationHandler = () => {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            //we just use it to play the sound
            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data && event.data.type === 'playSound') {
                    playNotificationSound()
                }
            })
        }
    }, [])

    // Function to play the notification sound
    const playNotificationSound = () => {
        const audio = new Audio('/assets/sounds/notification.wav') // Path to your sound file
        audio.play().catch(error => {
            console.error('Error playing sound:', error)
        })
    }

    return null // This component doesn't render anything
}

export default NotificationHandler
