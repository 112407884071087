import { Suspense } from 'react'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../contexts/AuthContext'

const QuickActionForm: React.FC = () => {
    const { DynamicComponent, selectedAction, handleProceed, currentScreenItem } = useCurrentScreen()
    const { t } = useTranslation()
    const methods = useForm({})
    const { user } = useAuth()
    const { control, setValue, watch } = methods
    return (
        <Suspense fallback={<>Loading...</>}>
            {DynamicComponent ? (
                <DynamicComponent
                    onProceed={(prm: any) => handleProceed(prm, user!.userName, user!.OpUserID, user!.OrganizationID)}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    title={t(selectedAction?.label + '')}
                    dropDownURL={selectedAction?.bulkDropDownURL}
                    dropDownLabel={null}
                    item={currentScreenItem.item}
                />
            ) : (
                <p>comes nothing</p>
            )}
        </Suspense>
    )
}
export default QuickActionForm
