interface TokenPayload {
    token: string
    expiresAt: number
}

export const authService = {
    saveToken: (token: string, refreshToken: string, expiresIn: number, keepLoggedIn: boolean) => {
        const tokenPayload: TokenPayload = {
            token,
            expiresAt: Date.now() + expiresIn * 1000, // expiresIn is usually in seconds
        }

        if (keepLoggedIn) {
            localStorage.setItem('authToken', JSON.stringify(tokenPayload))
            localStorage.setItem('refreshToken', refreshToken)
        } else {
            sessionStorage.setItem('authToken', JSON.stringify(tokenPayload))
            sessionStorage.setItem('refreshToken', refreshToken)
        }
    },

    getToken: () => {
        const storedToken = localStorage.getItem('authToken') || sessionStorage.getItem('authToken')
        return storedToken ? JSON.parse(storedToken) : null
    },

    getRefreshToken: () => {
        return localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken')
    },

    removeToken: () => {
        localStorage.removeItem('authToken')
        localStorage.removeItem('refreshToken')
        sessionStorage.removeItem('authToken')
        sessionStorage.removeItem('refreshToken')
    },

    isAuthenticated: () => {
        const tokenPayload = authService.getToken()
        if (!tokenPayload) return false

        // Check if token is expired
        return Date.now() < tokenPayload.expiresAt
    },

    isKeepMeLoggedIn: () => {
        const tokenPayload = authService.getToken()
        if (!tokenPayload) return false

        return true
    },

    isTokenExpired: () => {
        const tokenPayload = authService.getToken()
        if (!tokenPayload) return true

        // Check if the token has expired
        return Date.now() >= tokenPayload.expiresAt
    },
}
