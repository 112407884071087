import {
    Box,
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    styled,
    useTheme,
    Typography,
} from '@mui/material'
import CustomAvatar from '../../CustomAvatar/CustomAvatar'
import { IUser } from '../../../Views/Administration/User/IUser'
import { IMenu } from '../../../Views/Administration/Menu/IMenu'
import CustomDrawerLanguagesMenu from '../CustomDrawerLanguagesMenu/CustomDrawerLanguagesMenu'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoutIcon from '@mui/icons-material/Logout'
import EditIcon from '@mui/icons-material/Edit'
import SupportIcon from '@mui/icons-material/Support'
import { IconButton } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { Methods } from '../../../../Utils/Utils'
import CustomDrawerSingleLevel from '../CustomDrawerSingleLevel/CustomDrawerSingleLevel'
import { useAuth } from '../../../../contexts/AuthContext'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import StyledBadge from '../CustomDrawerStyledBadge/CustomDrawerStyledBadge'

const DrawerHeaderRight = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}))

interface CustomDrawerRightProps {
    openRight: boolean
    handleOpenRight(prm: boolean): void
    handleOpenMyProfile(): void
    handleOpenMyMessages(): void
}

const CustomDrawerRight = (props: CustomDrawerRightProps) => {
    const { t } = useTranslation()
    const { handleOpenRight, openRight, handleOpenMyProfile, handleOpenMyMessages } = props
    const [open, setOpen] = useState(false)
    const { setCurrentPageName, setCurrentPageIcon, resetStackAndPushMenu } = useCurrentScreen()
    const { user, logout } = useAuth()
    const drawerWidth = 300
    const [openMyProfile, setOpenMyProfile] = useState(false)
    const navigate = useNavigate()

    const MultiLevel = ({ item }: any) => {
        const { children } = item
        const [openMulti, setOpenMulti] = useState(false)
        const handleClick = () => {
            setOpenMulti(prev => !prev)
        }

        return (
            <>
                <ListItem
                    key={item.MenuID}
                    disablePadding
                    sx={{
                        display: 'block',
                        zIndex: theme => theme.zIndex.modal + 1,
                    }}
                    onClick={handleClick}
                    id={item.MenuID}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                ml: item.ParentMenuID != null ? 2 * item.MenuLevel + 'rem' : '3',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={item.Name}>
                                <Box
                                    sx={{
                                        mr: '.5rem',
                                    }}
                                >
                                    <CustomSVG strSVG={item.Icon} />
                                </Box>
                            </Tooltip>
                        </ListItemIcon>

                        <ListItemText primary={item.Name} />
                        {openMulti ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMulti} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((child: any, key: any) => {
                            if (child.IsHiddenInNavBar) return null
                            return <MenuItemCustom key={key} item={child} />
                        })}
                    </List>
                </Collapse>
            </>
        )
    }

    const MenuItemCustom = useCallback(
        ({ item }: any) => {
            if (typeof item !== 'undefined') {
                const Component = Methods.hasChildren(item) ? MultiLevel : CustomDrawerSingleLevel
                if (Methods.hasChildren(item)) {
                    return <Component item={item} />
                } else {
                    return <Component item={item} open={open} handleListItemClick={handleListItemClick} />
                }
            } else {
                return <>aaa</>
            }
        },
        [user!.menus]
    )

    const handleDrawerCloseRight = () => {
        handleOpenRight(false)
    }

    const handleLogout = () => {
        logout()
    }
    const handlImageUpdated = (filename: string) => {
        console.log('updated')
    }

    const handleOpenProfile = () => {
        //setOpenMyProfile(true)
        handleOpenMyProfile()
    }
    const handleListItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()

        const result = Methods.findInJSON(user!.menus, e.currentTarget.id, 'MenuID', 'children')
        resetStackAndPushMenu(result.MenuID)
        switch (e.currentTarget.id) {
            case 'logout':
                setOpen(false)
                handleOpenRight(false)
                handleLogout()
                break
            case 'myProfile':
                //setOpenMyProfile(true)
                handleOpenRight(false)
                setOpen(false)
                handleOpenMyProfile()

                break
            case 'support':
                handleOpenRight(false)
                setOpen(false)
                //setCurrentPageIcon(result.Icon)
                navigate('tickets')
                setCurrentPageName(t('Tickets'))
                break
            case 'myMessages':
                //setOpenMyProfile(true)
                handleOpenRight(false)
                setOpen(false)
                handleOpenMyMessages()

                break

            case 'myControlPanel':
                setOpenMyProfile(false)

                handleOpenRight(false)
                setOpen(false)
                navigate('controlpanel')
                setCurrentPageName(user!.OrganizationDSCR)
                break

            default:
                setOpen(false)
                handleOpenRight(false)

                navigate(result.Route)
                setCurrentPageName(result.Name)
                setCurrentPageIcon(result.Icon)
                break
        }
        e.stopPropagation()
    }
    return (
        <Drawer
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                },
            }}
            variant="temporary"
            //variant="persistent"
            anchor="right"
            open={openRight}
            onClose={handleDrawerCloseRight}
        >
            <Box>
                <DrawerHeaderRight>
                    <List sx={{ width: '100%', maxWidth: 360 }}>
                        <ListItem
                            alignItems="flex-start"
                            key={'myProfile'}
                            sx={{ display: 'block', p: 0, m: 0 }}
                            onClick={handleListItemClick}
                            id={'myProfile'}
                        >
                            <ListItemAvatar sx={{ p: 0, display: 'flex', gap: 2, alignItems: 'center' }}>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <CustomAvatar
                                        itemType="images/user"
                                        keyName="OpUserID"
                                        item={user! as unknown as IUser}
                                        onImageUpdated={handlImageUpdated}
                                        sizeHeight={56}
                                        sizeWidth={56}
                                        justViewMode={true}
                                        // hasIcon={true}
                                        noPreview={true}
                                        // onClick={handleOpenProfile}
                                    />
                                </StyledBadge>
                                <ListItemText>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        onClick={handleOpenProfile}
                                    >
                                        {user?.userName}
                                    </Typography>
                                    <IconButton onClick={handleOpenProfile}>
                                        <EditIcon />
                                    </IconButton>
                                </ListItemText>
                            </ListItemAvatar>
                        </ListItem>
                    </List>
                </DrawerHeaderRight>
            </Box>
            <Box>
                <List>
                    {user!.menus.filter(
                        (itm: IMenu) => typeof itm.IsConfigMenu != 'undefined' && Boolean(itm.IsConfigMenu) === true
                    ).length > 0 && (
                        <>
                            <Divider />

                            <List>
                                {user!.menus.map(
                                    (item, key) =>
                                        Boolean(item.IsHiddenInNavBar) !== true &&
                                        (Boolean(item.IsConfigMenu) === true ||
                                            typeof item.IsConfigMenu === 'undefined') && (
                                            <MenuItemCustom key={key} item={item} />
                                        )
                                )}
                            </List>
                        </>
                    )}
                    <CustomDrawerLanguagesMenu />

                    <Divider />
                    <ListItem
                        key={'logout'}
                        disablePadding
                        sx={{ display: 'block' }}
                        onClick={handleListItemClick}
                        id={'logout'}
                    >
                        <ListItemButton
                            data-test="drawer-logout-button"
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Tooltip title={t('Logout')}>
                                    <LogoutIcon />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t('Logout')} sx={{ opacity: openRight ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Box sx={{ position: 'absolute', bottom: '0px', display: 'block', width: '100%', textAlign: 'right' }}>
                <List>
                    <ListItem
                        key={'support'}
                        disablePadding
                        sx={{ display: 'block' }}
                        onClick={handleListItemClick}
                        id={'support'}
                    >
                        <ListItemButton
                            data-test="drawer-logout-button"
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Tooltip title={t('Support')}>
                                    <SupportIcon />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t('Support')} sx={{ opacity: openRight ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}
export default CustomDrawerRight
