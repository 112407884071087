import { Box, Divider, SwipeableDrawer } from '@mui/material'
import './_CommonActionsDialog.scss'
import React, { Suspense, lazy, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommonAlert from '../../CommonAlert/CommonAlert'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../../contexts/AuthContext'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import ActionMenusButton from '../ActionMenusButton/ActionMenusButton'
import QuickActionForm from '../QuickActionForm/QuickActionForm'

interface CustomDialogProps {
    componentPath?: string // The path to the component
}
const CommonActionsDialog = () => {
    const { t } = useTranslation()
    const methods = useForm({})

    const { user } = useAuth()
    const {
        selectedRecordIDs,
        actions,
        selectedAction,
        openQuickActionDialog,
        openSwipeScreenDialog,
        setOpenSwipeScreenDialog,
        currentScreenItem,
        setSelectedAction,
    } = useCurrentScreen()

    const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        // Close the drawer if swipe down or certain keyboard events occur
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setOpenSwipeScreenDialog(isOpen)
        setSelectedAction(null)
    }

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={openSwipeScreenDialog}
            onClose={() => {
                setOpenSwipeScreenDialog(false)
                setSelectedAction(null)
            }}
            onOpen={toggleDrawer(true)}
            disableSwipeToOpen={true}
            disableDiscovery={true}
            PaperProps={{
                className: 'swipeUp',
            }}
        >
            <Box
                sx={{
                    width: 'auto',
                    textAlign: 'center',
                }}
            >
                <Box sx={{ marginBottom: '1.5rem' }}>
                    <Divider className="swipeBar"></Divider>
                </Box>

                <Box
                    sx={{
                        paddingBottom: '1.5rem',
                        backgroundColor: 'Transparent',
                    }}
                >
                    <CommonAlert />
                    {openSwipeScreenDialog &&
                        !openQuickActionDialog &&
                        actions.map((action, index) => (
                            <ActionMenusButton
                                key={index}
                                action={action}
                                selectedRecordIDs={selectedRecordIDs}
                                index={index}
                            />
                        ))}

                    {openSwipeScreenDialog && openQuickActionDialog && <QuickActionForm />}
                </Box>
            </Box>
        </SwipeableDrawer>
    )
}

export default CommonActionsDialog
